import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Truck, Calendar, Home, ClipboardList, Scale, Camera, CheckCircle } from 'lucide-react';

export default function Hero() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    moveDate: '',
    homeSize: '',
    inventoryMethod: '',
    specialtyItems: []
  });

  const homeSizes = [
    { value: 'studio', label: 'Studio (400-600 sq ft)' },
    { value: '1bed', label: '1 Bedroom (600-800 sq ft)' },
    { value: '2bed', label: '2 Bedrooms (800-1200 sq ft)' },
    { value: '3bed', label: '3 Bedrooms (1200-2000 sq ft)' },
    { value: '4bed', label: '4+ Bedrooms (2000+ sq ft)' },
    { value: 'piano', label: 'Piano Only' },
    { value: 'pool', label: 'Pool Table' },
    { value: 'specialty', label: 'Other Specialty Item' }
  ];

  const inventoryMethods = [
    {
      id: 'manual',
      icon: <ClipboardList className="w-6 h-6" />,
      title: 'Manual Input',
      description: 'Create a detailed list of your items room by room',
      benefits: [
        'Complete control over inventory',
        'Add special instructions per item',
        'Most accurate quotes',
        'Perfect for detailed planning'
      ]
    },
    {
      id: 'weight',
      icon: <Scale className="w-6 h-6" />,
      title: 'Quick Estimate',
      description: 'Fast estimate based on home size',
      benefits: [
        'Fast and simple process',
        'Based on industry averages',
        'Perfect for rough estimates',
        'Instant pricing available'
      ]
    },
    {
      id: 'ai-scan',
      icon: <Camera className="w-6 h-6" />,
      title: 'AI Room Scanner',
      description: 'Use your camera to scan and list items',
      benefits: [
        'Quick and accurate',
        'AI-powered recognition',
        'Room-by-room scanning',
        'Automated measurements'
      ]
    }
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    localStorage.setItem('moveDetails', JSON.stringify(formData));
    navigate('/quote', { 
      state: { moveDetails: formData } 
    });
  };

  const isFormValid = formData.moveDate && formData.homeSize && formData.inventoryMethod;

  return (
    <div className="relative bg-gradient-to-r from-blue-600 to-blue-700 text-white">
      <div className="absolute inset-0 bg-black opacity-10"></div>
      <div className="relative container mx-auto px-4 py-24">
        <div className="max-w-4xl mx-auto text-center">
          <div className="flex items-center justify-center gap-2 mb-6">
            <Truck className="w-8 h-8" />
            <span className="text-2xl font-bold">MoveMatch</span>
          </div>
          <h1 className="text-5xl font-bold mb-6">
            Moving Made Simple
          </h1>
          <p className="text-xl mb-12 text-blue-100">
            Get instant quotes from trusted movers in your area
          </p>

          <form onSubmit={handleSubmit} className="bg-white/10 backdrop-blur-md rounded-xl p-6 mb-12">
            {/* Moving Date */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-1">Moving Date</label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-200 w-5 h-5" />
                <input
                  type="date"
                  required
                  className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-white/30"
                  value={formData.moveDate}
                  onChange={(e) => setFormData({...formData, moveDate: e.target.value})}
                />
              </div>
            </div>

            {/* Create Inventory */}
            <div className="mb-6">
              <div className="text-center mb-4">
                <h2 className="text-lg font-semibold mb-1">Choose Your Inventory Method</h2>
                <p className="text-sm text-blue-100">Select the most convenient way to create your moving inventory</p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {inventoryMethods.map((method) => (
                  <button
                    key={method.id}
                    type="button"
                    onClick={() => setFormData({...formData, inventoryMethod: method.id})}
                    className={`p-4 rounded-lg text-left transition duration-200 ${
                      formData.inventoryMethod === method.id
                        ? 'bg-white text-blue-600'
                        : 'bg-white/10 hover:bg-white/20'
                    }`}
                  >
                    <div className="flex items-center gap-3 mb-2">
                      {method.icon}
                      <span className="font-semibold">{method.title}</span>
                    </div>
                    <p className={`text-sm mb-3 ${
                      formData.inventoryMethod === method.id
                        ? 'text-blue-600'
                        : 'text-blue-100'
                    }`}>
                      {method.description}
                    </p>
                    <ul className={`text-sm space-y-1 ${
                      formData.inventoryMethod === method.id
                        ? 'text-blue-600'
                        : 'text-blue-100'
                    }`}>
                      {method.benefits.map((benefit, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <CheckCircle className="w-4 h-4 flex-shrink-0" />
                          <span>{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </button>
                ))}
              </div>
            </div>

            {/* Home Size */}
            <div className="mb-6">
              <label className="block text-sm font-medium mb-1">Home Size or Specialty Item</label>
              <div className="relative">
                <Home className="absolute left-3 top-1/2 transform -translate-y-1/2 text-blue-200 w-5 h-5" />
                <select
                  required
                  className="w-full pl-10 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-white/30 appearance-none"
                  value={formData.homeSize}
                  onChange={(e) => setFormData({...formData, homeSize: e.target.value})}
                >
                  <option value="" className="text-gray-700">Select size or item</option>
                  {homeSizes.map(size => (
                    <option key={size.value} value={size.value} className="text-gray-700">
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button
              type="submit"
              className={`w-full px-8 py-4 rounded-lg font-semibold text-lg transition duration-200 ${
                isFormValid 
                  ? 'bg-white text-blue-600 hover:bg-blue-50' 
                  : 'bg-white/50 text-white/50 cursor-not-allowed'
              }`}
              disabled={!isFormValid}
            >
              Get Free Quote
            </button>
          </form>

          <div className="flex flex-wrap items-center justify-center gap-8 text-sm">
            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              <span>4.9/5 Average Rating</span>
            </div>
            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Licensed & Insured Movers</span>
            </div>
            <div className="flex items-center gap-2">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>Instant Quotes</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}