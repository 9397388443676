import { supabase } from '../supabase';
import { z } from 'zod';

// Validation schemas
const emailSchema = z.string().email('Invalid email format');
const passwordSchema = z.string().min(8, 'Password must be at least 8 characters');
const userTypeSchema = z.enum(['customer', 'mover']);

export const signUpSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
  userType: userTypeSchema,
  companyName: z.string().optional()
});

export const signInSchema = z.object({
  email: emailSchema,
  password: passwordSchema
});

// Auth functions with validation
export async function signUp(data: z.infer<typeof signUpSchema>) {
  const validated = signUpSchema.parse(data);

  const { data: authData, error } = await supabase.auth.signUp({
    email: validated.email,
    password: validated.password,
    options: {
      data: {
        user_type: validated.userType,
        company_name: validated.companyName
      }
    }
  });

  if (error) {
    throw new Error(error.message);
  }

  return authData;
}

export async function signIn(data: z.infer<typeof signInSchema>) {
  const validated = signInSchema.parse(data);

  const { data: authData, error } = await supabase.auth.signInWithPassword({
    email: validated.email,
    password: validated.password
  });

  if (error) {
    throw new Error(error.message);
  }

  return authData;
}

export async function signOut() {
  const { error } = await supabase.auth.signOut();
  
  if (error) {
    throw new Error(error.message);
  }
}

export async function resetPassword(email: string) {
  const validated = emailSchema.parse(email);

  const { error } = await supabase.auth.resetPasswordForEmail(validated);

  if (error) {
    throw new Error(error.message);
  }
}

export async function updatePassword(newPassword: string) {
  const validated = passwordSchema.parse(newPassword);

  const { error } = await supabase.auth.updateUser({
    password: validated
  });

  if (error) {
    throw new Error(error.message);
  }
}

// Session management
export async function getSession() {
  const { data: { session }, error } = await supabase.auth.getSession();
  
  if (error) {
    throw new Error(error.message);
  }
  
  return session;
}

export async function getUser() {
  const { data: { user }, error } = await supabase.auth.getUser();
  
  if (error) {
    throw new Error(error.message);
  }
  
  return user;
}