import React, { useState, useRef } from 'react';
import { Camera, Upload, Loader } from 'lucide-react';
import { detectItemsInImage, matchDetectedItems } from '../../lib/api/vision';
import ItemCard from './ItemCard';

interface AIVisionScannerProps {
  onSubmit: (items: any[]) => void;
}

export default function AIVisionScanner({ onSubmit }: AIVisionScannerProps) {
  const [scanning, setScanning] = useState(false);
  const [detectedItems, setDetectedItems] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [usingCamera, setUsingCamera] = useState(false);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setScanning(true);
      const base64Image = await fileToBase64(file);
      const items = await detectItemsInImage(base64Image);
      const matchedItems = await matchDetectedItems(items);
      setDetectedItems(prev => [...prev, ...matchedItems]);
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      setScanning(false);
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setUsingCamera(true);
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const captureImage = async () => {
    if (!videoRef.current) return;

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(videoRef.current, 0, 0);
    
    try {
      setScanning(true);
      const base64Image = canvas.toDataURL('image/jpeg').split(',')[1];
      const items = await detectItemsInImage(base64Image);
      const matchedItems = await matchDetectedItems(items);
      setDetectedItems(prev => [...prev, ...matchedItems]);
    } catch (error) {
      console.error('Error processing image:', error);
    } finally {
      setScanning(false);
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject as MediaStream;
    stream?.getTracks().forEach(track => track.stop());
    setUsingCamera(false);
  };

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result as string;
        resolve(base64.split(',')[1]);
      };
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = () => {
    onSubmit(detectedItems);
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-4">AI Room Scanner</h2>

        {/* Camera/Upload Controls */}
        <div className="flex gap-4 mb-6">
          <button
            onClick={() => usingCamera ? stopCamera() : startCamera()}
            className="flex-1 flex items-center justify-center gap-2 bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 transition duration-200"
          >
            <Camera className="w-5 h-5" />
            {usingCamera ? 'Stop Camera' : 'Start Camera'}
          </button>

          <button
            onClick={() => fileInputRef.current?.click()}
            className="flex-1 flex items-center justify-center gap-2 border border-blue-600 text-blue-600 px-4 py-3 rounded-lg hover:bg-blue-50 transition duration-200"
          >
            <Upload className="w-5 h-5" />
            Upload Photo
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileUpload}
          />
        </div>

        {/* Camera Preview */}
        {usingCamera && (
          <div className="relative mb-6">
            <video
              ref={videoRef}
              autoPlay
              playsInline
              className="w-full rounded-lg"
            />
            <button
              onClick={captureImage}
              disabled={scanning}
              className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-white text-blue-600 px-6 py-2 rounded-full shadow-lg hover:bg-blue-50 transition duration-200"
            >
              Capture
            </button>
          </div>
        )}

        {/* Scanning Indicator */}
        {scanning && (
          <div className="flex items-center justify-center gap-2 text-blue-600 mb-6">
            <Loader className="w-5 h-5 animate-spin" />
            <span>Analyzing image...</span>
          </div>
        )}

        {/* Detected Items */}
        {detectedItems.length > 0 && (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Detected Items</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {detectedItems.map((item, index) => (
                <ItemCard key={index} item={item} />
              ))}
            </div>
          </div>
        )}

        {/* Submit Button */}
        {detectedItems.length > 0 && (
          <button
            onClick={handleSubmit}
            className="mt-6 w-full bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition duration-200"
          >
            Submit Inventory ({detectedItems.length} items)
          </button>
        )}
      </div>
    </div>
  );
}