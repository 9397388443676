import React, { useState } from 'react';
import { Edit2, Trash2, Camera, Save, X } from 'lucide-react';
import { useUpdateInventoryItem, useDeleteInventoryItem } from '../../lib/api/queries';

interface ItemCardProps {
  item: any;
  isEditable?: boolean;
}

export default function ItemCard({ item, isEditable = true }: ItemCardProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: item.name,
    quantity: item.quantity,
    weight: item.weight,
    cubic_feet: item.cubic_feet,
    special_instructions: item.special_instructions
  });

  const updateMutation = useUpdateInventoryItem();
  const deleteMutation = useDeleteInventoryItem();

  const handleSave = async () => {
    try {
      await updateMutation.mutateAsync({
        itemId: item.id,
        updates: formData
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        await deleteMutation.mutateAsync(item.id);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      {isEditing ? (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
          
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Quantity</label>
              <input
                type="number"
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.quantity}
                onChange={(e) => setFormData({ ...formData, quantity: parseInt(e.target.value) })}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Weight (lbs)</label>
              <input
                type="number"
                step="0.1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.weight}
                onChange={(e) => setFormData({ ...formData, weight: parseFloat(e.target.value) })}
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Cubic Feet</label>
              <input
                type="number"
                step="0.1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                value={formData.cubic_feet}
                onChange={(e) => setFormData({ ...formData, cubic_feet: parseFloat(e.target.value) })}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Special Instructions</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              rows={2}
              value={formData.special_instructions}
              onChange={(e) => setFormData({ ...formData, special_instructions: e.target.value })}
            />
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={() => setIsEditing(false)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              <X className="w-4 h-4 mr-2" />
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="inline-flex items-center px-3 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
            >
              <Save className="w-4 h-4 mr-2" />
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-start mb-4">
            <div>
              <h3 className="text-lg font-semibold">{item.name}</h3>
              <p className="text-sm text-gray-500">{item.category}</p>
            </div>
            {isEditable && (
              <div className="flex gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
                <button
                  onClick={handleDelete}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>

          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <p className="text-sm text-gray-500">Quantity</p>
              <p className="font-medium">{item.quantity}</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Weight</p>
              <p className="font-medium">{item.weight} lbs</p>
            </div>
            <div>
              <p className="text-sm text-gray-500">Cubic Feet</p>
              <p className="font-medium">{item.cubic_feet} ft³</p>
            </div>
          </div>

          {item.special_instructions && (
            <div className="mb-4">
              <p className="text-sm text-gray-500">Special Instructions</p>
              <p className="text-sm">{item.special_instructions}</p>
            </div>
          )}

          {item.photos?.length > 0 && (
            <div>
              <p className="text-sm text-gray-500 mb-2">Photos</p>
              <div className="flex gap-2 overflow-x-auto">
                {item.photos.map((photo: string, index: number) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`${item.name} - ${index + 1}`}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                ))}
              </div>
            </div>
          )}

          {item.ai_confidence && (
            <div className="mt-4 flex items-center gap-2">
              <Camera className="w-4 h-4 text-blue-600" />
              <span className="text-sm text-gray-500">
                AI Detection Confidence: {Math.round(item.ai_confidence * 100)}%
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}