import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../lib/supabase';
import { CheckCircle, XCircle, DollarSign } from 'lucide-react';

interface Quote {
  id: string;
  customer_email: string;
  inventory: any;
  status: 'pending' | 'accepted' | 'rejected';
  created_at: string;
  price?: number;
}

export default function MoverPortal() {
  const { user } = useAuth();
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      fetchQuotes();
    }
  }, [user]);

  const fetchQuotes = async () => {
    try {
      const { data, error } = await supabase
        .from('quotes')
        .select('*')
        .eq('mover_id', user?.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setQuotes(data || []);
    } catch (error) {
      console.error('Error fetching quotes:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuoteResponse = async (quoteId: string, status: 'accepted' | 'rejected', price?: number) => {
    try {
      const { error } = await supabase
        .from('quotes')
        .update({ 
          status,
          price,
          responded_at: new Date().toISOString()
        })
        .eq('id', quoteId);

      if (error) throw error;
      await fetchQuotes(); // Refresh quotes after update
    } catch (error) {
      console.error('Error updating quote:', error);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Quote Requests</h1>
          <button
            onClick={() => fetchQuotes()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Refresh Quotes
          </button>
        </div>

        {quotes.length === 0 ? (
          <div className="bg-white rounded-lg shadow p-6 text-center">
            <p className="text-gray-500">No quote requests received yet.</p>
          </div>
        ) : (
          <div className="space-y-6">
            {quotes.map((quote) => (
              <div key={quote.id} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h2 className="text-xl font-semibold">Request from {quote.customer_email}</h2>
                    <span className="text-sm text-gray-500">
                      {new Date(quote.created_at).toLocaleDateString()} at{' '}
                      {new Date(quote.created_at).toLocaleTimeString()}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                      quote.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      quote.status === 'accepted' ? 'bg-green-100 text-green-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {quote.status.charAt(0).toUpperCase() + quote.status.slice(1)}
                    </span>
                  </div>
                </div>

                <div className="mb-6">
                  <h3 className="font-medium mb-2">Inventory Details:</h3>
                  <div className="bg-gray-50 p-4 rounded-lg overflow-auto max-h-60">
                    <pre className="whitespace-pre-wrap">
                      {JSON.stringify(quote.inventory, null, 2)}
                    </pre>
                  </div>
                </div>

                {quote.status === 'pending' ? (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Quote Amount ($)
                      </label>
                      <div className="relative max-w-xs">
                        <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                        <input
                          type="number"
                          className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full"
                          placeholder="Enter amount"
                          onChange={(e) => {
                            const price = parseFloat(e.target.value);
                            setQuotes(quotes.map(q => 
                              q.id === quote.id ? { ...q, price } : q
                            ));
                          }}
                        />
                      </div>
                    </div>
                    
                    <div className="flex gap-4">
                      <button
                        onClick={() => handleQuoteResponse(quote.id, 'accepted', quote.price)}
                        className="flex items-center gap-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                      >
                        <CheckCircle className="w-5 h-5" />
                        Accept Quote
                      </button>
                      <button
                        onClick={() => handleQuoteResponse(quote.id, 'rejected')}
                        className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-200"
                      >
                        <XCircle className="w-5 h-5" />
                        Reject Quote
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className={`p-4 rounded-lg ${
                    quote.status === 'accepted' ? 'bg-green-50 text-green-700' : 'bg-red-50 text-red-700'
                  }`}>
                    <div className="flex items-center gap-2">
                      {quote.status === 'accepted' ? (
                        <>
                          <CheckCircle className="w-5 h-5" />
                          <span>Quote accepted at ${quote.price}</span>
                        </>
                      ) : (
                        <>
                          <XCircle className="w-5 h-5" />
                          <span>Quote rejected</span>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}