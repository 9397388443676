import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn, signUp, signOut, type signUpSchema, type signInSchema } from '../lib/api/auth';
import { handleAuthError } from '../lib/api/error-handling';
import type { z } from 'zod';

export function useSupabaseAuth() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSignUp = useCallback(async (data: z.infer<typeof signUpSchema>) => {
    setIsLoading(true);
    setError(null);
    
    try {
      await signUp(data);
      navigate('/auth/verify-email');
    } catch (err) {
      const authError = handleAuthError(err as Error);
      setError(authError.message);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleSignIn = useCallback(async (data: z.infer<typeof signInSchema>) => {
    setIsLoading(true);
    setError(null);
    
    try {
      const { user } = await signIn(data);
      if (user?.user_metadata.user_type === 'customer') {
        navigate('/customer/dashboard');
      } else {
        navigate('/mover/dashboard');
      }
    } catch (err) {
      const authError = handleAuthError(err as Error);
      setError(authError.message);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  const handleSignOut = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      await signOut();
      navigate('/');
    } catch (err) {
      const authError = handleAuthError(err as Error);
      setError(authError.message);
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  return {
    isLoading,
    error,
    signUp: handleSignUp,
    signIn: handleSignIn,
    signOut: handleSignOut
  };
}