import React from 'react';

export default function MovingTips() {
  return (
    <div className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Expert Moving Tips</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Make your move smoother with these professional tips and guides.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              src="https://images.unsplash.com/photo-1600585152220-90363fe7e115?auto=format&fit=crop&q=80&w=800"
              alt="Packing boxes"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">Packing Tips & Tricks</h3>
              <p className="text-gray-600 mb-4">
                Learn professional packing techniques to protect your belongings during the move.
              </p>
              <a href="#" className="text-blue-600 font-semibold hover:text-blue-700">Read More →</a>
            </div>
          </div>

          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              src="https://images.unsplash.com/photo-1582408921715-18e7806365c1?auto=format&fit=crop&q=80&w=800"
              alt="Moving day preparation"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">Moving Day Checklist</h3>
              <p className="text-gray-600 mb-4">
                Essential steps to prepare for and manage your moving day effectively.
              </p>
              <a href="#" className="text-blue-600 font-semibold hover:text-blue-700">Read More →</a>
            </div>
          </div>

          <div className="rounded-lg overflow-hidden shadow-lg">
            <img
              src="https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&q=80&w=800"
              alt="New home organization"
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">Setting Up Your New Home</h3>
              <p className="text-gray-600 mb-4">
                Quick tips to efficiently organize and settle into your new space.
              </p>
              <a href="#" className="text-blue-600 font-semibold hover:text-blue-700">Read More →</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}