import { supabase } from '../supabase';

const VISION_API_URL = 'https://api.openai.com/v1/chat/completions';
const VISION_API_MODEL = 'gpt-4-vision-preview';

interface DetectedItem {
  name: string;
  category: string;
  confidence: number;
}

export async function detectItemsInImage(
  imageBase64: string,
  maxItems: number = 10
): Promise<DetectedItem[]> {
  try {
    const response = await fetch(VISION_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${import.meta.env.VITE_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: VISION_API_MODEL,
        messages: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `Identify up to ${maxItems} common household items in this image. For each item, provide: 
                1. Item name
                2. Category (Furniture, Electronics, Kitchen, etc.)
                3. Confidence score (0-1)
                Format as JSON array.`
              },
              {
                type: 'image_url',
                image_url: {
                  url: `data:image/jpeg;base64,${imageBase64}`
                }
              }
            ]
          }
        ],
        max_tokens: 1000
      })
    });

    const data = await response.json();
    return JSON.parse(data.choices[0].message.content);
  } catch (error) {
    console.error('Error detecting items:', error);
    throw new Error('Failed to analyze image');
  }
}

export async function matchDetectedItems(
  detectedItems: DetectedItem[]
): Promise<any[]> {
  const { data: templates, error } = await supabase
    .from('item_templates')
    .select('*')
    .in('name', detectedItems.map(item => item.name.toLowerCase()));

  if (error) throw error;

  return detectedItems.map(item => {
    const template = templates.find(
      t => t.name.toLowerCase() === item.name.toLowerCase()
    );
    return {
      ...item,
      template_id: template?.id,
      weight: template?.avg_weight,
      cubic_feet: template?.avg_cubic_feet,
      dimensions: template?.dimensions
    };
  });
}