import React, { useEffect, useState } from 'react';
import { supabase } from '../../lib/supabase';
import { Clock, CheckCircle, XCircle } from 'lucide-react';

interface Quote {
  id: string;
  mover_name: string;
  price: number;
  status: 'pending' | 'accepted' | 'rejected';
  created_at: string;
}

export default function CustomerPortal() {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchQuotes();
  }, []);

  const fetchQuotes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('quotes')
        .select('*')
        .eq('customer_id', user.id);

      if (error) throw error;
      setQuotes(data || []);
    } catch (error) {
      console.error('Error fetching quotes:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'pending':
        return <Clock className="w-5 h-5 text-yellow-500" />;
      case 'accepted':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'rejected':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold mb-8">My Moving Quotes</h1>

        {quotes.length === 0 ? (
          <div className="bg-white rounded-lg shadow p-6 text-center">
            <p className="text-gray-500">No quotes received yet.</p>
          </div>
        ) : (
          <div className="space-y-6">
            {quotes.map((quote) => (
              <div key={quote.id} className="bg-white rounded-lg shadow p-6">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-semibold">{quote.mover_name}</h2>
                  <div className="flex items-center gap-2">
                    {getStatusIcon(quote.status)}
                    <span className="capitalize">{quote.status}</span>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-gray-600">Quote Amount</p>
                    <p className="text-2xl font-bold">${quote.price}</p>
                  </div>
                  <div>
                    <p className="text-gray-600">Date Received</p>
                    <p>{new Date(quote.created_at).toLocaleDateString()}</p>
                  </div>
                </div>
                {quote.status === 'accepted' && (
                  <div className="mt-4 p-4 bg-green-50 rounded-lg">
                    <p className="text-green-700">
                      Quote accepted! The moving company will contact you shortly to arrange the details.
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}