import React, { useState } from 'react';
import { Home, Box, Scale, ArrowRight } from 'lucide-react';

interface Room {
  name: string;
  items: number;
  weight: number;
}

export default function WeightEstimate() {
  const [homeSize, setHomeSize] = useState('');
  const [furnishingLevel, setFurnishingLevel] = useState('');
  const [specialItems, setSpecialItems] = useState<string[]>([]);

  const homeSizes = [
    { value: 'studio', label: 'Studio (400-600 sq ft)' },
    { value: '1bed', label: '1 Bedroom (600-800 sq ft)' },
    { value: '2bed', label: '2 Bedrooms (800-1200 sq ft)' },
    { value: '3bed', label: '3 Bedrooms (1200-2000 sq ft)' },
    { value: '4bed', label: '4+ Bedrooms (2000+ sq ft)' }
  ];

  const furnishingLevels = [
    { value: 'minimal', label: 'Minimal - Basic furniture only' },
    { value: 'average', label: 'Average - Standard household items' },
    { value: 'heavy', label: 'Heavy - Fully furnished with many items' }
  ];

  const specialItemsList = [
    'Piano',
    'Pool Table',
    'Safe',
    'Large Exercise Equipment',
    'Antique Furniture',
    'Art Collection'
  ];

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle estimate submission
    console.log({
      homeSize,
      furnishingLevel,
      specialItems
    });
  };

  const toggleSpecialItem = (item: string) => {
    setSpecialItems(prev =>
      prev.includes(item)
        ? prev.filter(i => i !== item)
        : [...prev, item]
    );
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-2xl font-bold mb-6">Quick Estimate</h2>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Home Size Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Home Size
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {homeSizes.map(size => (
                <button
                  key={size.value}
                  type="button"
                  onClick={() => setHomeSize(size.value)}
                  className={`p-4 border rounded-lg text-left flex items-center gap-3 transition duration-200 ${
                    homeSize === size.value
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <Home className="w-5 h-5" />
                  <span>{size.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Furnishing Level */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Furnishing Level
            </label>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {furnishingLevels.map(level => (
                <button
                  key={level.value}
                  type="button"
                  onClick={() => setFurnishingLevel(level.value)}
                  className={`p-4 border rounded-lg text-left flex items-center gap-3 transition duration-200 ${
                    furnishingLevel === level.value
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <Box className="w-5 h-5" />
                  <span>{level.label}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Special Items */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Special Items
            </label>
            <p className="text-sm text-gray-500 mb-4">
              Select any special items that require additional attention
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {specialItemsList.map(item => (
                <button
                  key={item}
                  type="button"
                  onClick={() => toggleSpecialItem(item)}
                  className={`p-4 border rounded-lg text-left flex items-center gap-3 transition duration-200 ${
                    specialItems.includes(item)
                      ? 'border-blue-500 bg-blue-50 text-blue-700'
                      : 'border-gray-200 hover:border-blue-200'
                  }`}
                >
                  <Scale className="w-5 h-5" />
                  <span>{item}</span>
                </button>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!homeSize || !furnishingLevel}
            className="w-full bg-blue-600 hover:bg-blue-700 disabled:bg-gray-400 text-white font-semibold py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2"
          >
            Get Quick Estimate
            <ArrowRight className="w-5 h-5" />
          </button>
        </form>
      </div>

      {/* Estimate Display */}
      {homeSize && furnishingLevel && (
        <div className="mt-8 bg-white rounded-xl shadow-lg p-6">
          <h3 className="text-xl font-bold mb-4">Estimated Size</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="p-4 bg-blue-50 rounded-lg">
              <p className="text-sm text-gray-600 mb-1">Estimated Total Weight</p>
              <p className="text-3xl font-bold text-blue-700">
                {homeSize === 'studio' ? '2,000' : 
                 homeSize === '1bed' ? '3,500' :
                 homeSize === '2bed' ? '5,000' :
                 homeSize === '3bed' ? '7,500' :
                 '10,000'} lbs
              </p>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <p className="text-sm text-gray-600 mb-1">Number of Rooms</p>
              <p className="text-3xl font-bold text-gray-700">
                {homeSize === 'studio' ? '1' : 
                 homeSize === '1bed' ? '3' :
                 homeSize === '2bed' ? '5' :
                 homeSize === '3bed' ? '7' :
                 '9'}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}