import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthForm from './components/auth/AuthForm';
import VerifyEmail from './components/auth/VerifyEmail';
import ProtectedRoute from './components/auth/ProtectedRoute';
import TestAuth from './components/auth/TestAuth';
import Hero from './components/Hero';
import Features from './components/Features';
import MovingTips from './components/MovingTips';
import CustomerPortal from './components/portals/CustomerPortal';
import MoverPortal from './components/portals/MoverPortal';
import QuoteFlow from './components/QuoteFlow';
import { useAuth } from './context/AuthContext';

function App() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={
        <>
          <TestAuth />
          <Hero />
          <Features />
          <MovingTips />
        </>
      } />
      <Route path="/login" element={
        user ? <Navigate to="/dashboard" replace /> : <AuthForm mode="signin" />
      } />
      <Route path="/register" element={
        user ? <Navigate to="/dashboard" replace /> : <AuthForm mode="signup" />
      } />
      <Route path="/auth/verify-email" element={<VerifyEmail />} />
      <Route path="/quote" element={<QuoteFlow />} />

      {/* Protected Customer Routes */}
      <Route path="/customer/*" element={
        <ProtectedRoute userType="customer">
          <CustomerPortal />
        </ProtectedRoute>
      } />

      {/* Protected Mover Routes */}
      <Route path="/mover/*" element={
        <ProtectedRoute userType="mover">
          <MoverPortal />
        </ProtectedRoute>
      } />

      {/* Fallback */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;