export class SupabaseError extends Error {
  constructor(
    message: string,
    public code: string,
    public details?: unknown
  ) {
    super(message);
    this.name = 'SupabaseError';
  }
}

export class AuthError extends SupabaseError {
  constructor(message: string, code: string, details?: unknown) {
    super(message, code, details);
    this.name = 'AuthError';
  }
}

export class DatabaseError extends SupabaseError {
  constructor(message: string, code: string, details?: unknown) {
    super(message, code, details);
    this.name = 'DatabaseError';
  }
}

export function handleAuthError(error: Error): AuthError {
  if (error instanceof AuthError) return error;

  if (error.message.includes('Invalid login credentials')) {
    return new AuthError('Invalid email or password', 'AUTH_INVALID_CREDENTIALS');
  }
  if (error.message.includes('Email not confirmed')) {
    return new AuthError('Please verify your email address', 'AUTH_EMAIL_NOT_VERIFIED');
  }
  
  return new AuthError('Authentication failed', 'AUTH_UNKNOWN_ERROR', error);
}

export function handleDatabaseError(error: Error): DatabaseError {
  if (error instanceof DatabaseError) return error;

  if (error.message.includes('duplicate key')) {
    return new DatabaseError('Record already exists', 'DB_DUPLICATE_RECORD', error);
  }
  if (error.message.includes('foreign key')) {
    return new DatabaseError('Invalid reference', 'DB_FOREIGN_KEY_VIOLATION', error);
  }
  
  return new DatabaseError('Database operation failed', 'DB_UNKNOWN_ERROR', error);
}

export function logError(error: Error, context?: Record<string, unknown>) {
  console.error({
    name: error.name,
    message: error.message,
    stack: error.stack,
    context,
    timestamp: new Date().toISOString()
  });
}