import React, { useState, useCallback } from 'react';
import { Plus, Minus, Trash2 } from 'lucide-react';

interface InventoryItem {
  id: string;
  name: string;
  quantity: number;
  notes: string;
  category: string;
}

const categories = [
  {
    name: 'Living Room',
    items: ['Sofa', 'Coffee Table', 'TV Stand', 'Armchair', 'Bookshelf']
  },
  {
    name: 'Kitchen',
    items: ['Refrigerator', 'Microwave', 'Dining Table', 'Chairs', 'Appliances']
  },
  {
    name: 'Bedroom',
    items: ['Bed', 'Dresser', 'Nightstand', 'Wardrobe', 'Mirror']
  }
];

export default function ManualInventory() {
  const [items, setItems] = useState<InventoryItem[]>([]);
  const [currentItem, setCurrentItem] = useState({
    name: '',
    quantity: 1,
    notes: '',
    category: 'Living Room'
  });
  const [error, setError] = useState('');

  const validateItem = useCallback(() => {
    if (!currentItem.name.trim()) {
      setError('Please enter an item name');
      return false;
    }
    if (currentItem.quantity < 1) {
      setError('Quantity must be at least 1');
      return false;
    }
    setError('');
    return true;
  }, [currentItem]);

  const handleAddItem = useCallback(() => {
    if (!validateItem()) return;

    const newItem: InventoryItem = {
      id: Date.now().toString(),
      ...currentItem
    };

    setItems(prev => [...prev, newItem]);
    setCurrentItem({
      name: '',
      quantity: 1,
      notes: '',
      category: currentItem.category
    });
  }, [currentItem, validateItem]);

  const handleQuantityChange = useCallback((id: string, change: number) => {
    setItems(prev => prev.map(item => {
      if (item.id === id) {
        const newQuantity = Math.max(1, item.quantity + change);
        return { ...item, quantity: newQuantity };
      }
      return item;
    }));
  }, []);

  const handleRemoveItem = useCallback((id: string) => {
    setItems(prev => prev.filter(item => item.id !== id));
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <h2 className="text-2xl font-bold mb-6">Add Items to Your Inventory</h2>

        {/* Category Selection */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Room Category
          </label>
          <select
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            value={currentItem.category}
            onChange={(e) => setCurrentItem(prev => ({ ...prev, category: e.target.value }))}
          >
            {categories.map(cat => (
              <option key={cat.name} value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </div>

        {/* Item Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Item Name
          </label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            value={currentItem.name}
            onChange={(e) => {
              setError('');
              setCurrentItem(prev => ({ ...prev, name: e.target.value }));
            }}
            placeholder="Enter item name"
          />
        </div>

        {/* Quantity Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Quantity
          </label>
          <div className="flex items-center gap-2">
            <button
              onClick={() => setCurrentItem(prev => ({ 
                ...prev, 
                quantity: Math.max(1, prev.quantity - 1) 
              }))}
              className="p-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              type="button"
            >
              <Minus className="w-4 h-4" />
            </button>
            <input
              type="number"
              min="1"
              value={currentItem.quantity}
              onChange={(e) => {
                const val = parseInt(e.target.value) || 1;
                setCurrentItem(prev => ({ ...prev, quantity: Math.max(1, val) }));
              }}
              className="w-20 p-2 text-center border border-gray-300 rounded-lg"
            />
            <button
              onClick={() => setCurrentItem(prev => ({ 
                ...prev, 
                quantity: prev.quantity + 1 
              }))}
              className="p-2 border border-gray-300 rounded-lg hover:bg-gray-50"
              type="button"
            >
              <Plus className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Notes Input */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Notes (Optional)
          </label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
            value={currentItem.notes}
            onChange={(e) => setCurrentItem(prev => ({ ...prev, notes: e.target.value }))}
            placeholder="Add special instructions or notes"
          />
        </div>

        {error && (
          <div className="mb-4 p-2 bg-red-50 text-red-600 rounded-lg">
            {error}
          </div>
        )}

        <button
          onClick={handleAddItem}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200"
          type="button"
        >
          Add Item
        </button>
      </div>

      {/* Inventory List */}
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h3 className="text-xl font-bold mb-6">Your Inventory</h3>
        
        {items.length === 0 ? (
          <p className="text-center text-gray-500 py-4">
            No items in your inventory yet
          </p>
        ) : (
          <div className="space-y-4">
            {items.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between p-4 border border-gray-200 rounded-lg hover:bg-gray-50"
              >
                <div className="flex-grow">
                  <div className="flex items-center gap-4">
                    <span className="font-medium">{item.name}</span>
                    <span className="text-sm text-gray-500">{item.category}</span>
                  </div>
                  {item.notes && (
                    <p className="text-sm text-gray-500 mt-1">{item.notes}</p>
                  )}
                </div>
                
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() => handleQuantityChange(item.id, -1)}
                      className="p-1 text-gray-500 hover:text-gray-700"
                      type="button"
                    >
                      <Minus className="w-4 h-4" />
                    </button>
                    <span className="w-8 text-center">{item.quantity}</span>
                    <button
                      onClick={() => handleQuantityChange(item.id, 1)}
                      className="p-1 text-gray-500 hover:text-gray-700"
                      type="button"
                    >
                      <Plus className="w-4 h-4" />
                    </button>
                  </div>
                  <button
                    onClick={() => handleRemoveItem(item.id)}
                    className="text-red-500 hover:text-red-700"
                    type="button"
                  >
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {items.length > 0 && (
          <button
            className="mt-6 w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-lg transition duration-200"
            type="button"
          >
            Submit Inventory ({items.length} items)
          </button>
        )}
      </div>
    </div>
  );
}