import React from 'react';
import { supabase } from '../../lib/supabase';

export default function TestAuth() {
  const [status, setStatus] = React.useState('');
  const [error, setError] = React.useState('');

  const testConnection = async () => {
    try {
      setStatus('Testing connection...');
      const { data, error } = await supabase
        .from('profiles')
        .select('count')
        .single();

      if (error) throw error;
      setStatus('Connection successful!');
      console.log('Data:', data);
    } catch (err) {
      setError(err.message);
      setStatus('Connection failed');
    }
  };

  return (
    <div className="p-4">
      <button
        onClick={testConnection}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Test Connection
      </button>
      {status && <p className="mt-2">{status}</p>}
      {error && <p className="mt-2 text-red-500">{error}</p>}
    </div>
  );
}