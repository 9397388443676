import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';
import ManualInventory from './inventory/ManualInventory';
import WeightEstimate from './inventory/WeightEstimate';
import AIVisionScanner from './inventory/AIVisionScanner';
import { useCreateQuote } from '../lib/api/queries';
import { useAuth } from '../context/AuthContext';
import { Loader, ArrowLeft } from 'lucide-react';

type Step = 'contact' | 'inventory' | 'processing';

export default function QuoteFlow() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState<Step>('contact');
  const [formData, setFormData] = useState({
    contactInfo: null,
    inventoryMethod: null,
    inventory: null
  });

  const createQuoteMutation = useCreateQuote();

  const handleContactSubmit = (contactData: any) => {
    setFormData(prev => ({ ...prev, contactInfo: contactData }));
    // Get inventory method from localStorage that was set in Hero component
    const moveDetails = JSON.parse(localStorage.getItem('moveDetails') || '{}');
    setFormData(prev => ({ ...prev, inventoryMethod: moveDetails.inventoryMethod }));
    setCurrentStep('inventory');
  };

  const handleInventorySubmit = async (inventoryData: any) => {
    setFormData(prev => ({ ...prev, inventory: inventoryData }));
    setCurrentStep('processing');

    try {
      await createQuoteMutation.mutateAsync({
        customer_id: user?.id || '',
        mover_id: '', // Will be assigned when a mover accepts
        customer_email: formData.contactInfo.email,
        mover_name: '', // Will be filled when a mover accepts
        inventory: inventoryData,
        status: 'pending'
      });

      navigate('/customer/quotes');
    } catch (error) {
      console.error('Error creating quote:', error);
      // Handle error appropriately
    }
  };

  const handleBack = () => {
    switch (currentStep) {
      case 'inventory':
        setCurrentStep('contact');
        break;
      default:
        navigate('/');
    }
  };

  const renderInventoryComponent = () => {
    switch (formData.inventoryMethod) {
      case 'manual':
        return <ManualInventory onSubmit={handleInventorySubmit} />;
      case 'weight':
        return <WeightEstimate onSubmit={handleInventorySubmit} />;
      case 'ai-scan':
        return <AIVisionScanner onSubmit={handleInventorySubmit} />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-12">
        {/* Back Button */}
        <button
          onClick={handleBack}
          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 mb-8"
        >
          <ArrowLeft className="w-5 h-5" />
          Back
        </button>

        {/* Progress Steps */}
        <div className="mb-8">
          <div className="flex items-center justify-center space-x-4">
            <div className={`flex items-center ${currentStep === 'contact' ? 'text-blue-600' : 'text-gray-400'}`}>
              <span className={`w-8 h-8 rounded-full border-2 flex items-center justify-center font-semibold ${
                currentStep === 'contact' ? 'border-blue-600' : 'border-gray-400'
              }`}>1</span>
              <span className="ml-2">Contact Info</span>
            </div>
            <div className={`w-16 h-0.5 ${currentStep !== 'contact' ? 'bg-blue-600' : 'bg-gray-300'}`}></div>
            <div className={`flex items-center ${currentStep === 'inventory' ? 'text-blue-600' : 'text-gray-400'}`}>
              <span className={`w-8 h-8 rounded-full border-2 flex items-center justify-center font-semibold ${
                currentStep === 'inventory' ? 'border-blue-600' : 'border-gray-400'
              }`}>2</span>
              <span className="ml-2">Create Inventory</span>
            </div>
            <div className={`w-16 h-0.5 ${currentStep === 'processing' ? 'bg-blue-600' : 'bg-gray-300'}`}></div>
            <div className={`flex items-center ${currentStep === 'processing' ? 'text-blue-600' : 'text-gray-400'}`}>
              <span className={`w-8 h-8 rounded-full border-2 flex items-center justify-center font-semibold ${
                currentStep === 'processing' ? 'border-blue-600' : 'border-gray-400'
              }`}>3</span>
              <span className="ml-2">Processing</span>
            </div>
          </div>
        </div>

        {/* Step Content */}
        {currentStep === 'contact' && (
          <ContactForm onSubmit={handleContactSubmit} />
        )}

        {currentStep === 'inventory' && renderInventoryComponent()}

        {currentStep === 'processing' && (
          <div className="text-center py-12">
            <Loader className="w-12 h-12 text-blue-600 animate-spin mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-2">Processing Your Quote Request</h2>
            <p className="text-gray-600">
              We're sending your request to available movers in your area.
              You'll be notified when quotes are ready.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}